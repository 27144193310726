import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from "moment";

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
new Vue({
  router,
  store,
  moment,
  render: h => h(App),
  created: () => (document.documentElement.setAttribute('lang', 'ja')),
}).$mount('#app')

