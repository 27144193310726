<template>
  <div id="app">
    <LoadingDisplay :class="!isLoading ? 'd-none' : ''" />
    <router-view />
  </div>
</template>
<script>
import LoadingDisplay from "@/components/LoadingDisplay.vue";
import { mapState } from "vuex";

export default {
  components: {
    LoadingDisplay,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    setMeta(route) {
      if (route.meta.title) {
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      if (route.meta.desc) {
        let setDesc = route.meta.desc;
        if (document.querySelector("meta[name='description']")) {
          document
            .querySelector("meta[name='description']")
            .setAttribute("content", setDesc);
        }
      }
    },
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    $route(route) {
      this.setMeta(route);
    },
  },
};
</script>

<style>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  text-align: left;
  zoom: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
table {
  border-collapse: collapse;
  font-family: inherit;
}
h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  font-weight: normal;
  line-height: 1;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: 16px;
}
input[type="button"],
input[type="text"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}
textarea {
  resize: none;
  -webkit-appearance: none;
  border-radius: 0;
}
th,
td {
  border-collapse: collapse;
}
table th,
table td {
  white-space: nowrap;
}
ul,
ol {
  list-style-type: none;
}
img {
  vertical-align: text-bottom;
  vertical-align: -webkit-baseline-middle;
  max-width: 100%;
  height: auto;
  width: auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Noto Sans JP", sans-serif;
}
.d-none {
  display: none !important;
}
</style>
