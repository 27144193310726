import Vue from 'vue'
import Vuex from 'vuex'
import { 
  LOADING_START,
  LOADING_STOP, 
  SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE, 
  SAVING_NATIONAL_HEALTH_INSURANCE_SECTION,
  SAVING_CITIZEN_LIFE_SAFETY_SECTION,
} from "./action-types";
import {
  CHANGE_LOADING_STATUS,
  SET_SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE,
  SET_SAVING_NATIONAL_HEALTH_INSURANCE_SECTION,
  SET_SAVING_CITIZEN_LIFE_SAFETY_SECTION,
} from "./mutation-types";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    waitStatusDataToDisplay: null,
    haisaiCitizenSectionServiceOne: null,
    nationalHealthInsuranceSection: null,
    citizenLifeSafetySection: null,
  },
  getters: {
  },
  mutations: {
    [CHANGE_LOADING_STATUS](state, loadingStatus) {
      state.isLoading = loadingStatus;
    },
    [SET_SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE](state, value) {
      state.haisaiCitizenSectionServiceOne = value;
    },
    [SET_SAVING_NATIONAL_HEALTH_INSURANCE_SECTION](state, value) {
      state.nationalHealthInsuranceSection = value;
    },
    [SET_SAVING_CITIZEN_LIFE_SAFETY_SECTION](state, value) {
      state.citizenLifeSafetySection = value;
    },
  },
  actions: {
    [LOADING_START]({ commit }) {
      commit(CHANGE_LOADING_STATUS, true);
    },
    [LOADING_STOP]({ commit }) {
      commit(CHANGE_LOADING_STATUS, false);
    },
    [SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE]({ commit }, value) {
      commit(SET_SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE, value);
    },
    [SAVING_NATIONAL_HEALTH_INSURANCE_SECTION]({ commit }, value) {
      commit(SET_SAVING_NATIONAL_HEALTH_INSURANCE_SECTION, value);
    },
    [SAVING_CITIZEN_LIFE_SAFETY_SECTION]({ commit }, value) {
      commit(SET_SAVING_CITIZEN_LIFE_SAFETY_SECTION, value);
    },
  },
  modules: {
  }
})
