import Vue from 'vue'
import VueRouter from 'vue-router'
import waitingStatusScreen from '../views/WaitingStatusScreen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'waitingStatusScreen',
    component: waitingStatusScreen,
    meta: { title: '那覇市役所 待ち状況の確認', desc: '那覇市役所の待ち状況を確認できるページです。' }
  },
  {
    path: '/settings',
    name: 'waitingStatusSettings',
    component: () => import('../views/WaitingStatusSettings.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
