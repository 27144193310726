<template>
  <div class="home">
    <header v-if="!isClosed && !isOverElapsed">
      ハイサイ市民課業務（本庁舎）待ち状況
    </header>
    <section v-if="!isClosed && !isOverElapsed">
      <div class="sectionInner">
        <div class="updateDateTimeArea">
          <span>更新日時</span>
          <span v-if="result">{{ result.updateDateTime }}</span>
          <img
            src="@/assets/reload.png"
            :class="isLoading ? 'rotation' : ''"
            @click="reloadWaitingStatus"
          />
        </div>
        <table class="basicTable" v-if="result">
          <tr>
            <th>手続き名</th>
            <th>待ち数</th>
            <th>待ち時間</th>
          </tr>
          <tr>
            <th>転入</th>
            <td>{{ findWatingNumberOrTime("movingIn", "number") }}</td>
            <td rowspan="4">
              {{ findWatingNumberOrTime("movingIn", "time") }}
            </td>
          </tr>
          <tr>
            <th>転居</th>
            <td>{{ findWatingNumberOrTime("moving", "number") }}</td>
          </tr>
          <tr>
            <th>転出</th>
            <td>{{ findWatingNumberOrTime("movingOut", "number") }}</td>
          </tr>
          <tr>
            <th>外国人異動</th>
            <td>
              {{ findWatingNumberOrTime("foreignerMove", "number") }}
            </td>
          </tr>
        </table>
        <table class="basicTable" style="margin-top: 24px" v-if="result">
          <tr>
            <th>手続き名</th>
            <th>待ち数</th>
            <th>待ち時間</th>
          </tr>
          <tr>
            <th>印鑑登録</th>
            <td>{{ findWatingNumberOrTime("sealRegistration", "number") }}</td>
            <td rowspan="2">
              {{ findWatingNumberOrTime("sealRegistration", "time") }}
            </td>
          </tr>
          <tr>
            <th>住基カード</th>
            <td>
              {{
                findWatingNumberOrTime("basicResidentRegisterCard", "number")
              }}
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section v-else-if="!isClosed && isOverElapsed">
      <div class="closedDisplay">
        <img src="@/assets/top_title.png" alt="" />
        <h2>ただいまシステムメンテナンス中です</h2>
        <p>
          ご利用の皆様にはご迷惑をおかけし、<br
            class="dispSP"
          />大変申し訳ございません。
        </p>
        <p>メンテナンス終了まで<br class="dispSP" />今しばらくお待ち下さい。</p>
      </div>
    </section>
    <section v-else>
      <div class="closedDisplay">
        <img src="@/assets/top_title.png" alt="" />
        <h2>ただいま受付時間外です。</h2>
        <p>受付時間は平日の8時30分〜17時15分となります。</p>
        <p>受付時間内に再度アクセスしてください。</p>
        <p>※繫忙時期の休日開庁日は除く</p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import {
  LOADING_START,
  LOADING_STOP,
  SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE,
  SAVING_NATIONAL_HEALTH_INSURANCE_SECTION,
  SAVING_CITIZEN_LIFE_SAFETY_SECTION,
} from "@/store/action-types";

export default {
  name: "waitingStatusScreen",
  components: {},
  data() {
    return {
      result: null,
      isClosed: false,
      isOverElapsed: false,
      holidays: null,
      weekEnds: [0, 6],
      tempOpenDays: [], //"YYYY-MM-DD"
      baseUrl:
        "https://ww7smisxaf.execute-api.ap-northeast-1.amazonaws.com/dev/waiting-status/",
    };
  },
  async created() {
    await this.fetchOrClose();
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      waitStatusDataToDisplay: (state) => state.waitStatusDataToDisplay,
      haisaiCitizenSectionServiceOne: (state) =>
        state.haisaiCitizenSectionServiceOne,
      nationalHealthInsuranceSection: (state) =>
        state.nationalHealthInsuranceSection,
      citizenLifeSafetySection: (state) => state.citizenLifeSafetySection,
    }),
    isResultExist() {
      return this.result ? true : false;
    },
  },
  watch: {
    isResultExist(isResultExist) {
      //resultがある場合に比較を行う 更新日時から20分経過でisClosedの表示とする
      if (isResultExist) {
        const lastUpdating = moment(
          this.result.updateDateTime,
          "YYYY/MM/DD hh:mm"
        );
        const limitMinutes = -20;
        const currentTime = moment();

        this.isOverElapsed =
          lastUpdating.diff(currentTime, "minutes") <= limitMinutes
            ? true
            : false;
      }
    },
  },
  methods: {
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP,
      savingHaisaiCitizenSectionServiceOne:
        SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE,
      savingNationalHealthInsuranceSection:
        SAVING_NATIONAL_HEALTH_INSURANCE_SECTION,
      savingCitizenLifeSafetySection: SAVING_CITIZEN_LIFE_SAFETY_SECTION,
    }),
    async getWaitingStatus() {
      const headers = {
        "x-api-key": "tsi6gDv5qV9IfDsksRwL6aMyu9IG4swR68FVlayH",
      };

      const sectionType = this.$route.query.sectionType;
      const apiUrl = sectionType
        ? this.baseUrl + sectionType
        : this.baseUrl + "haisaiCitizenSectionServiceOne";
      await this.axios
        .get(apiUrl, {
          headers: headers,
          data: {},
        })
        .then((response) => {
          this.result = response.data.result;
          this.savingHaisaiCitizenSectionServiceOne(this.result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async reloadWaitingStatus() {
      await Promise.all([
        await this.loadingStart(),
        await this.getWaitingStatus(),
        await this.loadingStop(),
      ]);
    },
    findWatingNumberOrTime(targetType, timeOrNumber) {
      const targetArray = this.result.counterTypes.find(
        (counterArray) => counterArray.counterType == targetType
      );
      if (timeOrNumber == "time") {
        return targetArray.waitingTime;
      } else {
        return targetArray.waitingNumber;
      }
    },
    async fetchOrClose() {
      //TODO 管理画面が追加された場合、Promise.allで1.臨時開庁日取得してローカルstate上書き 2.isInCaseOfClosureJudgmentとすること
      this.isClosed = await this.isInCaseOfClosureJudgment();
      if (!this.isClosed) {
        await Promise.all([
          await this.loadingStart(),
          await this.getWaitingStatus(),
          await this.loadingStop(),
        ]);
      }
    },
    async isInCaseOfClosureJudgment() {
      // 営業時間チェック
      const startTime = moment("08:30:00", "HH:mm:ss");
      const endTime = moment("17:15:00", "HH:mm:ss");
      const currentTimeString = moment().format("HH:mm:ss");
      const currentTime = moment(currentTimeString, "HH:mm:ss");

      if (currentTime.isAfter(endTime) || currentTime.isBefore(startTime)) {
        return true;
      }

      // 日付チェックで必要なデータ用意
      const startMonthString = moment().startOf("month").format("YYYY-MM-DD");
      const endMonthString = moment().endOf("month").format("YYYY-MM-DD");
      const currentDate = moment();
      const holidayJp = require("@holiday-jp/holiday_jp"); // between以降に期間の開始日と終了日を指定
      this.holidays = holidayJp.between(
        new Date(startMonthString),
        new Date(endMonthString)
      );

      // 臨時開庁日チェック
      if (this.tempOpenDays.length) {
        for (let i = 0; i < this.tempOpenDays.length; i++) {
          if (currentDate.format("YYYY-MM-DD") == this.tempOpenDays[i]) {
            return false;
          }
        }
      }

      // 祝日チェック
      if (this.holidays.length) {
        for (let i = 0; i < this.holidays.length; i++) {
          const holiday = moment(this.holidays[i].date);
          if (currentDate.isSame(holiday, "day")) {
            return true;
          }
        }
      }

      // 土日チェック
      const isWeekEnd = this.weekEnds.find(
        (weekEnd) => weekEnd == currentDate.day()
      );
      if (isWeekEnd) {
        return true;
      }

      return false;
    },
  },
};
</script>
<style scoped>
.updateDateTimeArea {
  display: flex;
  align-items: center;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  margin: 12px 12px 24px;
}
.updateDateTimeArea span {
  display: block;
  padding: 12px 16px;
  font-size: 1.3rem;
}
.updateDateTimeArea span:first-child {
  background-color: #ceddef;
  font-size: 1.3rem;
  font-weight: 600;
  height: 54px;
  display: flex;
  align-items: center;
}
.updateDateTimeArea img {
  height: 28px;
  width: 28px;
  cursor: pointer;
}
header {
  padding: 24px;
  text-align: center;
  background-color: #1f3c88;
  color: #fff;
  font-size: 2.6rem;
  max-width: 100%;
}
.sectionInner {
  padding: 12px 12px 24px;
  max-width: 100%;
}
.basicTable {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
}

.basicTable td,
.basicTable th {
  text-align: center;
  height: 60px;
  padding: 6px 16px;
  max-width: 33%;
}

.basicTable th {
  min-width: auto;
  background-color: #ceddef;
  font-size: 1.6rem;
}

.basicTable td {
  background-color: #1f3c88;
  color: #fff;
  font-weight: 700;
  font-size: 1.8rem;
}
.closedDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.closedDisplay > h2 {
  font-weight: bold;
  margin-top: 100px;
}
.closedDisplay > p,
.closedDisplay > h2 {
  text-align: center;
  font-size: 1.6rem;
}
.closedDisplay > p:first-of-type {
  margin: 30px 0 0px;
}
.dispSP {
  display: none;
}
@media screen and (max-width: 1000px) {
  header {
    font-size: 2rem;
    max-width: 100%;
  }
  .basicTable th {
    font-size: 1.4rem;
  }
  .basicTable td {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .updateDateTimeArea span {
    display: block;
    padding: 12px 8px;
    font-size: 0.9rem;
  }
  .updateDateTimeArea span:first-child {
    font-size: 0.9rem;
    font-weight: 600;
    height: 54px;
  }
  .updateDateTimeArea img {
    height: 28px;
    width: 28px;
  }
  .updateDateTimeArea {
    justify-content: space-between;
    margin: 0 0 24px;
  }
  header {
    font-size: 1.6rem;
    padding: 12px;
  }
  .sectionInner {
    padding: 24px 12px 12px;
    max-width: 100%;
  }
  .basicTable {
    border-spacing: 2px;
    padding: 0;
  }
  .basicTable td,
  .basicTable th {
    height: 30px;
    padding: 6px 16px;
    max-width: 33%;
  }
  .basicTable th {
    min-width: auto;
    font-size: 1.1rem;
  }
  .basicTable td {
    font-size: 1.1rem;
  }
  .closedDisplay {
    padding: 0 16px;
  }
  .closedDisplay > h2 {
    margin-top: 70px;
  }
  .closedDisplay > p,
  .closedDisplay > h2 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 480px) {
  header {
    font-size: 1.1rem;
    padding: 12px;
  }
  .closedDisplay > h2 {
    margin-top: 50px;
  }
  .closedDisplay > p,
  .closedDisplay > h2 {
    font-size: 1.2rem;
  }
  .closedDisplay > p:first-of-type {
    margin: 30px 0 0px;
  }
  .dispSP {
    display: block;
  }
}

.rotation {
  -webkit-animation: rotation 3s linear infinite;
  animation: rotation 3s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
